// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { VendorRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "hostname": "smack-sportswear.olv.app",
    "environment": "production",
    "sportId": "various",
    "vendorId": "smack",
    "vendorName": "Smack Sportswear",
    "logoAsset": "smackSportswear_logo.png",
    "homeUrl": "https://www.smacksportswear.com/",
    "bakeryEmailTo": [
      "bill@smacksportswear.com",
      "brandon@smacksportswear.com",
      "customizers@olv.global"
    ],
    "orderEmail": "smack@smacksportswear.com",
    "features": {
      "shareDesign": true,
      "factoryOutput": true,
      "autoRotation": true,
      "fabric": true,
      "design": true,
      "productColor": true,
      "colorTone": false,
      "designColor": true,
      "teamName": true,
      "playerName": true,
      "playerNumber": true,
      "outline": true,
      "brandLogo": true,
      "teamLogo": true,
      "fonts": {
        "custom": true
      },
      "fill": false,
      "spectacleLogo": true,
      "textSize": true,
      "fullCustom": true
    },
    "defaults": {
      "brandLogoColorId": "white",
      "designColor1Id": "coolGray3C",
      "designColor2Id": "white",
      "designColor3Id": "282C",
      "designColor4Id": "coolGray8C",
      "teamName": {
        "font": "custom:Gotham/700",
        "colorId": "coolGray11C",
        "outline1ColorId": "coolGray3C"
      },
      "playerName": {
        "font": "custom:Gotham/700",
        "colorId": "coolGray11C",
        "outline1ColorId": "coolGray3C"
      },
      "playerNumber": {
        "font": "custom:Varsity Team/700",
        "colorId": "coolGray11C",
        "outline1ColorId": "coolGray3C",
        "outline2ColorId": "coolGray11C"
      }
    }
  },
  {
    "hostname": "smack-sportswear.cdev.orangelv.com",
    "environment": "staging",
    "sportId": "various",
    "vendorId": "smack",
    "vendorName": "Smack Sportswear",
    "logoAsset": "smackSportswear_logo.png",
    "homeUrl": "https://www.smacksportswear.com/",
    "bakeryEmailTo": [
      "customizers@olv.global"
    ],
    "orderEmail": "customizers@olv.global",
    "features": {
      "shareDesign": true,
      "factoryOutput": true,
      "autoRotation": true,
      "fabric": true,
      "design": true,
      "productColor": true,
      "colorTone": false,
      "designColor": true,
      "teamName": true,
      "playerName": true,
      "playerNumber": true,
      "outline": true,
      "brandLogo": true,
      "teamLogo": true,
      "fonts": {
        "custom": true
      },
      "fill": false,
      "spectacleLogo": true,
      "textSize": true,
      "fullCustom": true
    },
    "defaults": {
      "brandLogoColorId": "white",
      "designColor1Id": "coolGray3C",
      "designColor2Id": "white",
      "designColor3Id": "282C",
      "designColor4Id": "coolGray8C",
      "teamName": {
        "font": "custom:Gotham/700",
        "colorId": "coolGray11C",
        "outline1ColorId": "coolGray3C"
      },
      "playerName": {
        "font": "custom:Gotham/700",
        "colorId": "coolGray11C",
        "outline1ColorId": "coolGray3C"
      },
      "playerNumber": {
        "font": "custom:Varsity Team/700",
        "colorId": "coolGray11C",
        "outline1ColorId": "coolGray3C",
        "outline2ColorId": "coolGray11C"
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<VendorRow[]>> = data;

export type Vendor = typeof typed[number];

export const VENDOR_INDEX_KEY = "hostname";
export type VendorIndexKey = "hostname";
export type VendorHostname = Vendor["hostname"];

let i = 0;
export const VENDOR_DICT = {
  "smack-sportswear.olv.app": typed[i++],
  "smack-sportswear.cdev.orangelv.com": typed[i++]
} as const;

export { typed as VENDORS };
